import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { DateTime } from 'luxon';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import { options } from '../config/contentful';

export default function IssueTemplate({
  data: {
    issue: {
      title,
      body,
      excerpt: { excerpt },
      endorsements,
      experience,
      header: {
        // fixed: {src },
        fluid: { src, srcSet, sizes },
        description,
      },
    },
  }, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout container={false}>
      <SEO title={title} description={excerpt} image={src} />
      <Hero title={title} container={true} centered color="light" />
      <div className=" container section">
        <figure className="image is-3by1">
          {' '}
          <img
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            style={{ objectFit: 'cover' }}
            alt={description}
          />
        </figure>
      </div>
      <main className=" content" style={{ marginBottom: 48 }}>
        <div className="container is-narrow">{documentToReactComponents(body.json, options)}</div>

        {experience && (
          <div className="section">
            <div className="container is-narrow">
              <div className="notification is-info">
                <p className="heading is-size-4">Experience Matters</p>
                {documentToReactComponents(experience.json, options)}
              </div>
            </div>
          </div>
        )}

        {endorsements && (
          <div className="container">
            <hr className="divider" />
            <p className="heading is-size-4 has-text-centered">
              Community Leaders Know that Vanna has the experience we need:
            </p>
            {endorsements.map(
              ({
                title,
                body: { json },
                bio,
                photo: {
                  fluid: { src, sizes, srcSet },
                },
              }) => (
                <article
                  className="media"
                  style={{
                    // marginTop: 48,
                    marginBottom: 48,
                    // paddingTop: 48,
                    // borderTop: '2px solid #efefef',
                  }}
                >
                  <figure className="media-left">
                    <p className="image " style={{ maxWidth: 256 }}>
                      <img alt={`${title} picture`} src={src} sizes={sizes} srcSet={srcSet} />
                    </p>
                    <figcaption style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                      {title}
                    </figcaption>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <blockquote>
                        <div className="">{documentToReactComponents(json, options)}</div>
                        {bio && (
                          <cite>
                            <hr />
                            {bio.bio}
                          </cite>
                        )}
                      </blockquote>
                    </div>
                  </div>
                </article>
              ),
            )}
          </div>
        )}
      </main>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    issue: contentfulIssue(slug: { eq: $slug }) {
      contentful_id
      title
      body {
        json
      }
      excerpt {
        excerpt
      }

      experience {
        json
      }

      endorsements {
        slug
        title
        body {
          json
        }
        bio {
          bio
        }
        photo {
          fluid(maxWidth: 600) {
            sizes
            src
            srcSet
          }
        }
      }
      header {
        id
        title
        description
        fixed(width: 1920, height: 500) {
          src
        }
        fluid(maxWidth: 1920) {
          sizes
          src
          srcSet
        }
      }
    }
  }
`;
